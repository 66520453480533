import {useLocation} from 'react-router-dom'
import {useLayout} from '../../../core'
import {PageTitle} from './PageTitle'
import {DashboardPageTitle} from './DashboardPageTitle'
import {checkIsActive} from '../../../../helpers'

const PageTitleWrapper = () => {
  const {pathname} = useLocation()
  const {config} = useLayout()
  if (!config.app?.pageTitle?.display) {
    return null
  }

  if (checkIsActive(pathname, '/dashboard')) return <DashboardPageTitle />
  else return <PageTitle />
}

export {PageTitleWrapper}

import {FC, useEffect, useState} from 'react'
import {PageLink, PageTitle, useLayout} from '../../../_metronic/layout/core'
import {useParams, useSearchParams} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import OperationalStateBox from '../../components/OperationalStateBox'
import OperationalStatsBox from '../../components/OperationalStatsBox'
import ProductionMetricsBox from '../../components/ProductionMetricsBox'
import {ProductionLine} from '../../core/_models'
import {lookbackValueToTime, rfc3339ToString} from '../../core/time-util'
import LoadingWrapper from '../../components/LoadingWrapper'
import {useProductionLine} from '../../core/requests/factory'
import {useBatch, useBatches, useMachineOee} from '../../core/requests/oee'
import {useLookback} from '../../../_metronic/partials/layout/lookback/LookbackProvider'
import {ProductionStatusTimelineGraph} from './components/charts/ProductionStatusTimelineGraph'
import {ParetoGraph} from './components/charts/ParetoGraph'
import {GraphCard} from './components/GraphCard'
import {gaugeConfig} from './core/_chartConfigs/gaugeConfig'
import LogEventModal from '../../modals/log-event/LogEventModal'
import useModalState from '../../hooks/UseModalState'

const graphTabs = ['timeline', 'pareto']
type GraphTab = typeof graphTabs[number]

type Props = {
  productionLineId: string
  batchId?: string
}

const OeeDashboardPage: FC<Props> = ({productionLineId, batchId}) => {
  const [tab, setTab] = useState<GraphTab>('timeline')
  const [rotateTabs, setRotateTabs] = useState<boolean>(false)

  const {values: lookback, setActiveBatch} = useLookback()
  const startStopmodal = useModalState()

  const viewingSpecificBatch = batchId !== undefined

  const {data: productionLine, isLoading: productionLineIsLoading} =
    useProductionLine(productionLineId)
  const {data: activeBatch, isLoading: activeBatchIsLoading} = useBatch({batch: batchId})
  const {data: batches, isLoading: batchesIsLoading} = useBatches({
    productionLine: productionLineId,
    status: ['running'],
    options: {enabled: !viewingSpecificBatch && productionLine?.manufacturing_type === 'discrete'},
  })

  const lineIsContinuous = productionLine && productionLine.manufacturing_type === 'continuous'

  const batch = viewingSpecificBatch ? activeBatch : batches?.items.at(0)
  const batchIsLoading = viewingSpecificBatch ? activeBatchIsLoading : batchesIsLoading
  const productionLineDataIsLoading = productionLineIsLoading || batchIsLoading

  const startTime = lineIsContinuous
    ? lookbackValueToTime(lookback.productionLine)
    : batch?.start_time
  const endTime = batch?.end_time

  const {data: oee, isLoading: oeeIsLoading} = useMachineOee({
    id: productionLineId,
    startTime,
    endTime,
  })

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null

    const currentIndex = graphTabs.indexOf(tab)
    const nextIndex = (currentIndex + 1) % graphTabs.length

    if (rotateTabs) {
      interval = setInterval(() => {
        setTab(graphTabs[nextIndex])
      }, 5000)
    }

    return () => {
      if (interval) clearInterval(interval)
    }
  }, [tab, rotateTabs])

  useEffect(() => {
    if (batch) setActiveBatch(batch)
    return () => {
      setActiveBatch(undefined)
    }
  }, [batch])

  return (
    <LoadingWrapper loading={productionLineDataIsLoading}>
      {batch === undefined && !lineIsContinuous && (
        <EmptyOeeDashboardPage productionLine={productionLine!} />
      )}
      {productionLine && (batch || lineIsContinuous) && (
        <>
          {/* Top Cards */}
          <div className='row mb-5'>
            {/* Card 1 */}
            <div className='col-xl-8 mb-5 mb-xl-0'>
              <div className='card card-flush h-100'>
                <div className='card-body pt-5 pb-0'>
                  <div className='d-flex flex-wrap flex-sm-nowrap justify-content-between mb-6'>
                    {/* Info box */}
                    <div className='d-flex flex-column'>
                      <div className='row mb-2'>
                        <div className='d-flex flex-center flex-shrink-0 rounded w-100px h-100px'>
                          <img
                            className='w-100px'
                            src={
                              productionLine.gallery?.profile_image.url ||
                              toAbsoluteUrl('/media/hexastate/gallery-placeholder.jpg')
                            }
                            alt='Production Line Image'
                          />
                        </div>
                      </div>
                      {batch && (
                        <>
                          <a className='text-gray-800 fs-5 fw-bold mb-1'>
                            {batch.batch_number && <>{batch.batch_number} - </>}
                            {batch.name}
                          </a>
                          <a className='text-gray-800 fs-7 fw-bold'>
                            Started at:{' '}
                            {batch.start_time ? rfc3339ToString(batch.start_time) : 'N/A'}
                          </a>
                          <a className='text-gray-800 fs-7 fw-bold'>
                            Planned Completion:{' '}
                            {batch.end_time ? rfc3339ToString(batch.end_time) : 'N/A'}
                          </a>
                        </>
                      )}
                      {lineIsContinuous && (
                        <a className='text-gray-800 fs-7 fw-bold'>
                          Data since: {startTime ? rfc3339ToString(startTime) : 'N/A'}
                        </a>
                      )}
                    </div>

                    <ProductionMetricsBox batchId={batchId} productionLineId={productionLineId} />
                    <OperationalStatsBox machine={productionLine} />
                  </div>
                </div>
              </div>
            </div>

            {/* Card 2 */}
            <div className='col-xl-4'>
              <div className='card card-flush h-100'>
                <div className='card-body py-5'>
                  <div className='row h-100'>
                    <div className='col-4'>
                      <span className='d-inline-block h-100 w-100' tabIndex={0}>
                        <button
                          className='d-flex justify-content-center align-items-center bg-secondary rounded-3 p-2 mb-2 btn h-100 w-100'
                          onClick={startStopmodal.open}
                        >
                          <a className='text-black fs-2 fw-bold'>+ Event</a>
                        </button>
                      </span>
                    </div>
                    <div className='col-8 d-flex flex-column justify-content-between'>
                      <OperationalStateBox machineId={productionLine._id} />
                      <div className='bg-primary rounded-3 p-2 flex-grow-1'>
                        <a className='text-white fs-2 fw-bold d-flex align-items-center justify-content-center h-100'>
                          ETA:
                          <span className={`ms-2 mb-1`}>
                            {activeBatch?.expected_end_time !== undefined ? (
                              new Date(activeBatch.expected_end_time).toISOString()
                            ) : (
                              <i className='fa-solid fa-infinity text-white fs-7'></i>
                            )}
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='row'>
            {/* Gauges */}
            <div className='col-xl-5 mb-5 mb-xl-0'>
              <div className='row mb-5'>
                <div className='col-6'>
                  <GraphCard
                    title='OEE'
                    height='200px'
                    data={oee !== undefined ? Math.round(oee.oee * 100) : 0}
                    loading={oeeIsLoading}
                    chartId='oeeChart'
                    configureGraph={gaugeConfig}
                  />
                </div>
                <div className='col-6'>
                  <GraphCard
                    title='Performance'
                    height='200px'
                    data={oee !== undefined ? Math.round(oee.performance * 100) : 0}
                    loading={oeeIsLoading}
                    chartId='performanceChart'
                    configureGraph={gaugeConfig}
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col-6'>
                  <GraphCard
                    title='Availability'
                    height='200px'
                    data={oee !== undefined ? Math.round(oee.availability * 100) : 0}
                    loading={oeeIsLoading}
                    chartId='availabilityChart'
                    configureGraph={gaugeConfig}
                  />
                </div>
                <div className='col-6'>
                  <GraphCard
                    title='Quality'
                    height='200px'
                    data={oee !== undefined ? Math.round(oee.quality * 100) : 0}
                    loading={oeeIsLoading}
                    chartId='qualityChart'
                    configureGraph={gaugeConfig}
                  />
                </div>
              </div>
            </div>

            {/* Charts */}
            <div className='col-xl-7'>
              <div className='card card-flush'>
                <div className='card-header position-relative py-0 border-bottom-2'>
                  <ul className='nav nav-stretch nav-pills nav-pills-custom d-flex'>
                    {graphTabs.map((graphTab) => (
                      <li className='nav-item p-0 ms-0 me-8' key={graphTab}>
                        <a
                          className={`nav-link btn btn-color-muted px-0${
                            graphTab === tab ? ' active' : ''
                          }`}
                          onClick={() => setTab(graphTab)}
                        >
                          <span className='nav-text fw-semibold fs-4'>{graphTab}</span>
                          <span className='bullet-custom position-absolute z-index-2 w-100 h-2px top-100 bottom-n100 bg-primary rounded' />
                        </a>
                      </li>
                    ))}
                  </ul>

                  <div className='card-toolbar'>
                    <label className='form-check form-switch form-check-custom form-check-solid'>
                      <span className='form-check-label fw-semibold text-muted me-2'>
                        Auto Rotate
                      </span>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        checked={rotateTabs}
                        onChange={() => setRotateTabs(!rotateTabs)}
                      />
                    </label>
                  </div>
                </div>

                <div className='card-body'>
                  {tab === 'timeline' && (
                    <ProductionStatusTimelineGraph
                      productionLineId={productionLineId}
                      startTime={startTime}
                      endTime={endTime}
                      height='375px'
                    />
                  )}
                  {tab === 'pareto' && (
                    <ParetoGraph
                      productionLineId={productionLineId}
                      startTime={startTime}
                      endTime={endTime}
                      height='375px'
                    />
                  )}
                  {/* {tab === 'progress' && batch && (
                    <ProgressGraph
                      productionLineId={productionLineId}
                      batchId={batch._id}
                      startTime={batch?.start_time}
                      endTime={endTime}
                      height='375px'
                    />
                  )} */}
                </div>
              </div>
            </div>
          </div>

          <LogEventModal
            show={startStopmodal.isOpen}
            handleClose={startStopmodal.close}
            productionLineId={productionLineId}
          />
        </>
      )}
    </LoadingWrapper>
  )
}

const EmptyOeeDashboardPage: FC<{productionLine: ProductionLine}> = ({productionLine}) => {
  const startStopmodal = useModalState()

  return (
    <>
      {/* Top Cards */}
      <div className='row mb-5'>
        {/* Card 1 */}
        <div className='col-xl-8 mb-5 mb-xl-0'>
          <div className='card card-flush h-100'>
            <div className='card-body pt-5 pb-0'>
              <div className='d-flex flex-wrap flex-sm-nowrap justify-content-between mb-6'>
                {/* Info box */}
                <div className='d-flex flex-column'>
                  <div className='row mb-2'>
                    <div className='d-flex flex-center flex-shrink-0 rounded w-100px h-100px'>
                      <img
                        className='w-100px'
                        src={
                          productionLine?.gallery?.profile_image.url ||
                          toAbsoluteUrl('/media/hexastate/gallery-placeholder.jpg')
                        }
                        alt='Production Line Image'
                      />
                    </div>
                  </div>
                  <a className='text-gray-800 fs-7 fw-bold'>Started at:</a>
                  <a className='text-gray-800 fs-7 fw-bold'>Planned Completion:</a>
                </div>

                {/* Next box */}
                <div className='d-flex flex-column justify-content-between'>
                  <div>
                    <div className='d-flex align-items-center mb-2'>
                      <a className='text-gray-800 fs-7 fw-bold me-1'>Parts Produced:</a>
                    </div>
                    <div className='d-flex align-items-center mb-1'>
                      <a className='text-gray-800 fs-7 fw-bold me-1'>Average Production Rate:</a>
                    </div>
                    <div className='d-flex align-items-center'>
                      <a className='text-gray-800 fs-7 fw-bold me-1'>Average Cycle Time:</a>
                    </div>
                  </div>
                  <div>
                    <div className='d-flex align-items-center mb-1'>
                      <a className='text-gray-800 fs-7 fw-bold me-1'>Scrap:</a>
                    </div>
                    <div className='d-flex align-items-center'>
                      <a className='text-gray-800 fs-7 fw-bold me-1'>Scrap Rate:</a>
                    </div>
                  </div>
                </div>

                {/* Last box */}
                <div className='d-flex flex-column'>
                  <div className='d-flex align-items-center mb-1'>
                    <a className='text-gray-800 fs-7 fw-bold me-1'>Unscheduled Downtime:</a>
                  </div>
                  <div className='d-flex align-items-center mb-1'>
                    <a className='text-gray-800 fs-7 fw-bold me-1'>Number of Stops:</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Card 1 */}
        <div className='col-xl-4'>
          <div className='card card-flush h-100'>
            <div className='card-body py-5'>
              <div className='row h-100'>
                <div className='col-4'>
                  <span className='d-inline-block h-100 w-100' tabIndex={0}>
                    <button
                      className='d-flex justify-content-center align-items-center bg-secondary rounded-3 p-2 mb-2 btn h-100 w-100'
                      onClick={startStopmodal.open}
                    >
                      <a className='text-black fs-2 fw-bold'>+ Event</a>
                    </button>
                  </span>
                </div>
                <div className='col-8 d-flex flex-column justify-content-between'>
                  <OperationalStateBox machineId={productionLine._id} />
                  <div className='bg-primary rounded-3 p-2 flex-grow-1'>
                    <a className='text-white fs-2 fw-bold d-flex align-items-center justify-content-center h-100'>
                      NO BATCH RUNNING
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <LogEventModal
        show={startStopmodal.isOpen}
        handleClose={startStopmodal.close}
        productionLineId={productionLine._id}
      />
    </>
  )
}

const OeeDashboardWrapper: FC = () => {
  let {id} = useParams()
  let [searchParams] = useSearchParams()
  const {data: productionLine} = useProductionLine(id, {options: {enabled: !!id}})
  const {setDateRangePickerButton} = useLayout()

  useEffect(() => {
    if (productionLine?.manufacturing_type === 'discrete') {
      setDateRangePickerButton(false)
    }
    return () => {
      setDateRangePickerButton(true)
    }
  }, [productionLine])

  function breadcrumbs(): Array<PageLink> {
    if (!productionLine) return []

    return [
      {
        title: productionLine.area.name,
        path: '',
        isSeparator: false,
        isActive: false,
      },
      {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
      },
    ]
  }

  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs()}>{productionLine?.name}</PageTitle>
      <OeeDashboardPage productionLineId={id!} batchId={searchParams.get('batch') || undefined} />
    </>
  )
}

export {OeeDashboardWrapper}

import {formatDistance} from 'date-fns'
import {Asset} from './_models'

const getAssetStatus = (asset: Asset) => {
  const {last_state_change_at, state} = asset
  const distance = last_state_change_at
    ? formatDistance(new Date(last_state_change_at), new Date(), {addSuffix: true})
    : null
  const status = last_state_change_at && state !== 'Offline' ? `${state} since ${distance}` : state
  return status
}

export {getAssetStatus}

import React from 'react'
import {Route, Routes} from 'react-router-dom'
import {SelectedRowsProvider} from '../../providers/SelectedRowsProvider'
import {SuspensedView} from '../../routing/PrivateRoutes'

const DashboardsPage = React.lazy(() => import('./DashboardsPage'))

const DashboardsRouter = () => (
  <Routes>
    <Route
      path={':id?'}
      element={
        <SuspensedView>
          <SelectedRowsProvider idField='id'>
            <DashboardsPage />
          </SelectedRowsProvider>
        </SuspensedView>
      }
    />
  </Routes>
)

export default DashboardsRouter

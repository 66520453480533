import React from 'react'
import {format} from 'date-fns'
import {CopyClipboardText} from '../../components/content/CopyClipboardText'
import EntityInlineTextFieldForm from '../../components/form-components/EntityInlineTextFieldForm'
import {Asset} from '../../core/_models'
import {deleteAsset, useUpdateAssetMutation} from '../../core/requests/factory'
import {ColorCell} from '../../pages/overview/core/columns/ColorCell'
import {NameImageCell} from '../../pages/overview/core/columns/ImageStringCell'
import useEntityDeleteModal from '../entity-delete/useEntityDeleteModal'
import OverviewDetailsModal, {BaseOverviewDetailsModalProps} from './OverviewDetailsModal'

const AssetOverviewDetailsModal = ({
  entity,
  onHide,
  show = true,
}: BaseOverviewDetailsModalProps<Asset>) => {
  const deleteModal = useEntityDeleteModal({
    entity,
    deleteHandler: deleteAsset,
    queryToInvalidate: 'assets',
  })
  return (
    <OverviewDetailsModal
      show={show}
      onHide={onHide}
      title={
        <>
          <span className={`text-nowrap`}>Asset Details</span> ・
          <EntityInlineTextFieldForm
            data={entity}
            dataField={'name'}
            useMutation={useUpdateAssetMutation}
            queryToInvalidate={'assets'}
          />
          {deleteModal.handler}
        </>
      }
      objectId={entity._id}
      timelineId={entity.production_line?._id}
      attributes={[
        ['ID', <CopyClipboardText text={entity._id} />],
        [
          'Gallery',
          <NameImageCell
            index={1}
            str={''}
            images={
              entity.gallery
                ? [entity.gallery.profile_image, ...(entity.gallery.images ?? [])]
                : undefined
            }
            entityId={entity._id}
            queryToInvalidate='assets'
          />,
        ],
        ['Organization Name', entity.organization.name],
        ['Factory Name', entity.factory.name],
        ['Area Name', entity.area.name],
        ['Production Line Name', entity.production_line?.name || '-'],
        ['Section Name', entity.section?.name || '-'],
        ['Name', entity.name],
        ['Profile Sensitivity Preset', entity.profile?.sensitivity_preset],
        [
          'State',
          <div className={`container`}>
            <div className={`row`}>
              <div className='col'>
                <ColorCell color={`state-${entity.state.toLowerCase()}`} />
              </div>
            </div>
            <div className={`row`}>
              <div className='col col-5 m-2'>First State</div>
              <div className='col m-2 d-flex justify-content-end'>
                {entity.first_state_at ? format(new Date(entity.first_state_at), 'PPpp') : '-'}
              </div>
            </div>
            <div className={`row`}>
              <div className='col col-5 m-2'>Last State</div>
              <div className='col m-2 d-flex justify-content-end'>
                {entity.last_state_at ? format(new Date(entity.last_state_at), 'PPpp') : '-'}
              </div>
            </div>
            <div className={`row`}>
              <div className='col col-5 m-2'>Last State Change</div>
              <div className='col m-2 d-flex justify-content-end'>
                {entity.last_state_change_at
                  ? format(new Date(entity.last_state_change_at), 'PPpp')
                  : '-'}
              </div>
            </div>
          </div>,
        ],
      ]}
      displayAssetTree={true}
    />
  )
}

export {AssetOverviewDetailsModal}

import React from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {useDisplayMode} from '../../../../_metronic/partials/layout/display-mode/DisplayModeProvider'
import {SelectedRowsProvider} from '../../../providers/SelectedRowsProvider'
import {OeeDashboardWrapper} from '../../dashboard/OeeDashboardWrapper'
import {PowerMonitoringDashboardWrapper} from '../../dashboard/PowerMonitoringDashboardWrapper'
import {ProductionLinesWrapper} from './ProductionLinesWrapper'

const breadcrumbs: Array<PageLink> = [
  {
    title: 'Production Lines',
    path: '/production-lines/overview',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ProductionLinesPage = () => {
  const {displayMode, updateDisplayMode} = useDisplayMode()

  React.useEffect(() => {
    if (displayMode !== 'energy' && displayMode !== 'oee') updateDisplayMode('oee')
  }, [displayMode])

  return (
    <Routes>
      <Route
        path='overview/grid?'
        element={
          <SelectedRowsProvider>
            <PageTitle breadcrumbs={breadcrumbs}>Overview</PageTitle>
            <ProductionLinesWrapper />
          </SelectedRowsProvider>
        }
      />
      <Route
        path=':id'
        element={
          <>
            {displayMode === 'energy' && <PowerMonitoringDashboardWrapper />}
            {displayMode === 'oee' && <OeeDashboardWrapper />}
            {displayMode === 'condition' && <></>}
          </>
        }
      />
      <Route index element={<Navigate to='/production-lines/overview' />} />
    </Routes>
  )
}

export default ProductionLinesPage

import React from 'react'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import StatesPageContent from './states/StatesPageContent'

const StatesPage = () => {
  const breadcrumbs: Array<PageLink> = [
    {
      title: 'Staff',
      path: '/staff/states',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>States</PageTitle>
      <StatesPageContent />
    </>
  )
}

export default StatesPage

import {AssetState} from '../../../../core/_models'
import {useAssets, useGateways, useSensors} from '../../../../core/requests/factory'
import {PieGraph} from '../../components/charts/PieGraph'
import {PieData} from '../../core/_chartConfigs/pieConfig'
import {WidgetProps} from './Widget'
import * as am5 from '@amcharts/amcharts5'

const PieWidget = ({widget, height}: WidgetProps) => {
  const {data: assets} = useAssets({
    area: widget.entity,
    options: {enabled: widget.entity_group === 'asset', refetchOnWindowFocus: false},
  })
  const {data: sensors} = useSensors({
    area: widget.entity,
    options: {enabled: widget.entity_group === 'sensor', refetchOnWindowFocus: false},
  })
  const {data: gateways} = useGateways({
    area: widget.entity,
    options: {enabled: widget.entity_group === 'gateway', refetchOnWindowFocus: false},
  })

  const entities =
    widget.entity_group === 'asset'
      ? assets?.items
      : widget.entity_group === 'sensor'
      ? sensors?.items
      : gateways?.items

  const data: PieData[] = []
  for (const entity of entities || []) {
    const index = data.findIndex((d) => d.label === entity.state)
    if (index !== -1) data[index].value++
    else data.push({label: entity.state, value: 1, color: StateToColor(entity.state)})
  }

  return <PieGraph id={widget._id} height={height} data={data} />
}

const StateToColor = (state: AssetState) => {
  switch (state) {
    case 'Offline':
      return am5.color('#7d85b9')
    case 'Online':
      return am5.color('#228002')
    case 'Calibrating':
      return am5.color('#b2b2b2')
    case 'Good':
      return am5.color('#228002')
    case 'Satisfactory':
      return am5.color('#9cd98d')
    case 'Unsatisfactory':
      return am5.color('#e58c0f')
    case 'Unacceptable':
      return am5.color('#ff0202')
  }
}

export default PieWidget

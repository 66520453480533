import {User} from '@sentry/react'
import React from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import {Invitation, Reference} from '../../core/_models'
import {useProfile} from '../../core/requests/auth'
import {SelectedRowsProvider} from '../../providers/SelectedRowsProvider'
import {OrganizationWrapper} from './OrganizationWrapper'

export interface InvitationProfile extends User, Reference {
  invitation?: Invitation
}

const OrganizationPage = () => {
  const {data: profile} = useProfile()

  const breadcrumbs = [
    {
      title: 'Manage Your Organization',
      path: '/organization',
      isActive: true,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  return (
    <SelectedRowsProvider idField='email'>
      <PageTitle breadcrumbs={breadcrumbs}>{profile?.organization?.name || ''}</PageTitle>
      <OrganizationWrapper />
    </SelectedRowsProvider>
  )
}

export {OrganizationPage}

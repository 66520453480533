import {Link} from 'react-router-dom'
import {Column} from 'react-table'
import {getAssetStatus} from '../../../core/asset-util'
import {ColorCell} from '../core/columns/ColorCell'
import {StringCell} from '../core/columns/StringCell'
import {SelectionCell} from '../core/columns/SelectionCell'
import {CustomHeader} from '../core/columns/CustomHeader'
import {SelectionHeader} from '../core/columns/SelectionHeader'
import {Asset} from '../../../core/_models'
import {NameImageCell} from '../core/columns/ImageStringCell'
import {CellClickContext} from '../core/columns/TableBody'
import AreaCell from '../core/columns/AreaCell'
import FactoryCell from '../core/columns/FactoryCell'

const Columns: ReadonlyArray<Column<Asset>> = [
  {
    Header: (props) => <SelectionHeader tableProps={props} tableId='hs_assets_table' />,
    id: 'selection',
    Cell: ({...props}) => {
      const {_id} = props.data[props.row.index]
      return <SelectionCell id={_id} tableId='hs_assets_table' />
    },
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Name' className='min-w-125px' />,
    id: 'name',
    Cell: ({...props}) => {
      const {name, gallery, _id} = props.data[props.row.index]
      const images = gallery ? [gallery.profile_image, ...(gallery.images ?? [])] : undefined
      return (
        <CellClickContext.Consumer>
          {({onCellClick}) => (
            <NameImageCell
              str={name}
              images={images}
              index={props.row.index}
              entityId={_id}
              queryToInvalidate='assets'
              onClick={() => (onCellClick ? onCellClick(_id) : undefined)}
            />
          )}
        </CellClickContext.Consumer>
      )
    },
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Area' className='min-w-125px' />,
    id: 'area',
    Cell: ({...props}) => {
      const {area} = props.data[props.row.index]
      return <AreaCell areaReference={area} />
    },
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Factory' className='min-w-125px' />,
    id: 'factory',
    Cell: ({...props}) => {
      const {factory} = props.data[props.row.index]
      return <FactoryCell factoryReference={factory} />
    },
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Status' className='min-w-125px' />,
    id: 'status',
    Cell: ({...props}) => <StringCell str={getAssetStatus(props.data[props.row.index])} />,
  },
  {
    Header: (props) => <CustomHeader tableProps={props} className='min-w-25px' />,
    id: 'stateColor',
    Cell: ({...props}) => {
      const {state} = props.data[props.row.index]
      return <ColorCell color={`state-${state.toLowerCase()}`} />
    },
  },
  {
    Header: (props) => <CustomHeader tableProps={props} className={`w-50px`} />,
    id: '_id',
    Cell: ({...props}) => {
      const {_id} = props.data[props.row.index]
      return (
        <Link to={`/assets/${_id}`} className={`btn btn-primary`}>
          <span className={`fa fa-chevron-right`} />
        </Link>
      )
    },
  },
]

export {Columns}

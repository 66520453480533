import {useEffect, useRef} from 'react'
import * as am5 from '@amcharts/amcharts5'
import {useAssetGraphs} from '../../../../core/requests/factory'
import {ChartLoader} from '../ChartLoader'
import {healthChartConfig} from '../../core/_chartConfigs/healthChartConfig'

type HealthGraphProps = {
  assetId: string
  type: 'simple' | 'advanced'
  startTime?: string
  endTime?: string
  height: string
  chartId?: string
}

const HealthGraph = ({
  assetId,
  type,
  startTime,
  endTime,
  height,
  chartId = `${type}-health-${assetId}`,
}: HealthGraphProps) => {
  const chartRef = useRef<am5.Root | null>(null)

  const {data: assetGraphs} = useAssetGraphs({assetId, startTime, endTime})

  const loading = assetGraphs === undefined

  useEffect(() => {
    if (loading) return

    const data =
      type === 'simple' ? assetGraphs.simple_health_history : assetGraphs.advanced_health_history

    chartRef.current = healthChartConfig(chartId, data)

    return () => {
      chartRef.current?.dispose()
    }
  }, [assetGraphs, loading])

  return <ChartLoader loading={loading} chartId={chartId} height={height} />
}

export {HealthGraph}

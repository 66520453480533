/* eslint-disable */
import * as types from './graphql'
import {TypedDocumentNode as DocumentNode} from '@graphql-typed-document-node/core'

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
  '\n  fragment FolderItem on Folder {\n    id\n    name\n    description\n    parentFolder\n    isShared\n  }\n':
    types.FolderItemFragmentDoc,
  '\n  mutation CreateDashboard($input: CreateDashboardInput!) {\n    dashboards {\n      createDashboard(input: $input) {\n        id\n        folder\n        name\n        description\n      }\n    }\n  }\n':
    types.CreateDashboardDocument,
  '\n  mutation CreateFolder($input: CreateFolderInput!) {\n    folders {\n      createFolder(input: $input) {\n        id\n        name\n        parentFolder\n      }\n    }\n  }\n':
    types.CreateFolderDocument,
  '\n  mutation DeleteDashboard($input: DeleteDashboardInput!) {\n    dashboards {\n      deleteDashboard(input: $input)\n    }\n  }\n':
    types.DeleteDashboardDocument,
  '\n  mutation DeleteFolder($input: DeleteFolderInput!) {\n    folders {\n      deleteFolder(input: $input)\n    }\n  }\n':
    types.DeleteFolderDocument,
  '\n  query GetFolder($id: ID!) {\n    getFolder(id: $id) {\n      id\n      isShared\n      name\n      parentFolder\n      hierarchy {\n        id\n        name\n        order\n      }\n    }\n  }\n':
    types.GetFolderDocument,
  '\n  query GetUserDashboards($folder: String) {\n    getUserDashboards(folder: $folder) {\n      id\n      name\n      isShared\n    }\n  }\n':
    types.GetUserDashboardsDocument,
  '\n  query GetUserFolders($parentFolder: String) {\n    getUserFolders(parentFolder: $parentFolder) {\n      id\n      name\n      parentFolder\n      isShared\n    }\n  }\n':
    types.GetUserFoldersDocument,
  '\n  query GetPlacementPowerConsumptionHistory(\n    $id: ID!\n    $startTime: DateTime\n    $endTime: DateTime\n    $timespan: Timespan\n    $multiplier: Int!\n  ) {\n    getPlacementPowerConsumptionHistory(\n      id: $id\n      startTime: $startTime\n      endTime: $endTime\n      timespan: $timespan\n      multiplier: $multiplier\n    ) {\n      time\n      value\n    }\n  }\n':
    types.GetPlacementPowerConsumptionHistoryDocument,
  '\n  query GetProductionLinePowerConsumptionHistory(\n    $id: ID!\n    $startTime: DateTime\n    $endTime: DateTime\n    $timespan: Timespan\n    $multiplier: Int!\n  ) {\n    getProductionLinePowerConsumptionHistory(\n      id: $id\n      startTime: $startTime\n      endTime: $endTime\n      timespan: $timespan\n      multiplier: $multiplier\n    ) {\n      time\n      value\n    }\n  }\n':
    types.GetProductionLinePowerConsumptionHistoryDocument,
  '\n  query GetSectionPowerConsumptionHistory(\n    $id: ID!\n    $startTime: DateTime\n    $endTime: DateTime\n    $timespan: Timespan\n    $multiplier: Int!\n  ) {\n    getSectionPowerConsumptionHistory(\n      id: $id\n      startTime: $startTime\n      endTime: $endTime\n      timespan: $timespan\n      multiplier: $multiplier\n    ) {\n      time\n      value\n    }\n  }\n':
    types.GetSectionPowerConsumptionHistoryDocument,
  '\n  query GetProductionLineConsumptionByCategory(\n    $id: ID!\n    $masterPlacement: String!\n    $placements: [ID!]!\n    $operationalStates: [OperationalStates!]!\n    $operationalIntervalMins: [Float!]!\n    $operationalIntervalMaxs: [Float!]!\n    $startTime: DateTime\n    $endTime: DateTime\n  ) {\n    getProductionLineConsumptionByCategory(\n      id: $id\n      masterPlacement: $masterPlacement\n      placements: $placements\n      operationalStates: $operationalStates\n      operationalIntervalMins: $operationalIntervalMins\n      operationalIntervalMaxs: $operationalIntervalMaxs\n      startTime: $startTime\n      endTime: $endTime\n    ) {\n      consumptions {\n        consumption\n        state\n        time\n      }\n    }\n  }\n':
    types.GetProductionLineConsumptionByCategoryDocument,
  '\n  query GetPlacementStandbyConsumption(\n    $id: ID!\n    $startTime: DateTime\n    $endTime: DateTime\n    $timespan: Timespan\n    $multiplier: Int!\n  ) {\n    getPlacementStandbyConsumption(\n      id: $id\n      startTime: $startTime\n      endTime: $endTime\n      timespan: $timespan\n      multiplier: $multiplier\n    ) {\n      costs\n      currency\n      emissions\n      energy\n      power {\n        time\n        value\n      }\n    }\n  }\n':
    types.GetPlacementStandbyConsumptionDocument,
  '\n  query GetProductionLineStandbyConsumption(\n    $id: ID!\n    $startTime: DateTime\n    $endTime: DateTime\n    $timespan: Timespan\n    $multiplier: Int!\n  ) {\n    getProductionLineStandbyConsumption(\n      id: $id\n      startTime: $startTime\n      endTime: $endTime\n      timespan: $timespan\n      multiplier: $multiplier\n    ) {\n      costs\n      currency\n      emissions\n      energy\n      power {\n        time\n        value\n      }\n    }\n  }\n':
    types.GetProductionLineStandbyConsumptionDocument,
  '\n  query GetSectionStandbyConsumption(\n    $id: ID!\n    $startTime: DateTime\n    $endTime: DateTime\n    $timespan: Timespan\n    $multiplier: Int!\n  ) {\n    getSectionStandbyConsumption(\n      id: $id\n      startTime: $startTime\n      endTime: $endTime\n      timespan: $timespan\n      multiplier: $multiplier\n    ) {\n      costs\n      currency\n      emissions\n      energy\n      power {\n        time\n        value\n      }\n    }\n  }\n':
    types.GetSectionStandbyConsumptionDocument,
  '\n  query GetPlacementTotalConsumption(\n    $id: ID!\n    $startTime: DateTime\n    $endTime: DateTime\n    $timespan: Timespan\n    $multiplier: Int!\n  ) {\n    getPlacementTotalConsumption(\n      id: $id\n      startTime: $startTime\n      endTime: $endTime\n      timespan: $timespan\n      multiplier: $multiplier\n    ) {\n      costs\n      currency\n      emissions\n      energy\n      power {\n        time\n        value\n      }\n    }\n  }\n':
    types.GetPlacementTotalConsumptionDocument,
  '\n  query GetProductionLineTotalConsumption(\n    $id: ID!\n    $startTime: DateTime\n    $endTime: DateTime\n    $timespan: Timespan\n    $multiplier: Int!\n  ) {\n    getProductionLineTotalConsumption(\n      id: $id\n      startTime: $startTime\n      endTime: $endTime\n      timespan: $timespan\n      multiplier: $multiplier\n    ) {\n      costs\n      currency\n      emissions\n      energy\n      power {\n        time\n        value\n      }\n    }\n  }\n':
    types.GetProductionLineTotalConsumptionDocument,
  '\n  query GetSectionTotalConsumption(\n    $id: ID!\n    $startTime: DateTime\n    $endTime: DateTime\n    $timespan: Timespan\n    $multiplier: Int!\n  ) {\n    getSectionTotalConsumption(\n      id: $id\n      startTime: $startTime\n      endTime: $endTime\n      timespan: $timespan\n      multiplier: $multiplier\n    ) {\n      costs\n      currency\n      emissions\n      energy\n      power {\n        time\n        value\n      }\n    }\n  }\n':
    types.GetSectionTotalConsumptionDocument,
}

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment FolderItem on Folder {\n    id\n    name\n    description\n    parentFolder\n    isShared\n  }\n'
): typeof documents['\n  fragment FolderItem on Folder {\n    id\n    name\n    description\n    parentFolder\n    isShared\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation CreateDashboard($input: CreateDashboardInput!) {\n    dashboards {\n      createDashboard(input: $input) {\n        id\n        folder\n        name\n        description\n      }\n    }\n  }\n'
): typeof documents['\n  mutation CreateDashboard($input: CreateDashboardInput!) {\n    dashboards {\n      createDashboard(input: $input) {\n        id\n        folder\n        name\n        description\n      }\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation CreateFolder($input: CreateFolderInput!) {\n    folders {\n      createFolder(input: $input) {\n        id\n        name\n        parentFolder\n      }\n    }\n  }\n'
): typeof documents['\n  mutation CreateFolder($input: CreateFolderInput!) {\n    folders {\n      createFolder(input: $input) {\n        id\n        name\n        parentFolder\n      }\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation DeleteDashboard($input: DeleteDashboardInput!) {\n    dashboards {\n      deleteDashboard(input: $input)\n    }\n  }\n'
): typeof documents['\n  mutation DeleteDashboard($input: DeleteDashboardInput!) {\n    dashboards {\n      deleteDashboard(input: $input)\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation DeleteFolder($input: DeleteFolderInput!) {\n    folders {\n      deleteFolder(input: $input)\n    }\n  }\n'
): typeof documents['\n  mutation DeleteFolder($input: DeleteFolderInput!) {\n    folders {\n      deleteFolder(input: $input)\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GetFolder($id: ID!) {\n    getFolder(id: $id) {\n      id\n      isShared\n      name\n      parentFolder\n      hierarchy {\n        id\n        name\n        order\n      }\n    }\n  }\n'
): typeof documents['\n  query GetFolder($id: ID!) {\n    getFolder(id: $id) {\n      id\n      isShared\n      name\n      parentFolder\n      hierarchy {\n        id\n        name\n        order\n      }\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GetUserDashboards($folder: String) {\n    getUserDashboards(folder: $folder) {\n      id\n      name\n      isShared\n    }\n  }\n'
): typeof documents['\n  query GetUserDashboards($folder: String) {\n    getUserDashboards(folder: $folder) {\n      id\n      name\n      isShared\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GetUserFolders($parentFolder: String) {\n    getUserFolders(parentFolder: $parentFolder) {\n      id\n      name\n      parentFolder\n      isShared\n    }\n  }\n'
): typeof documents['\n  query GetUserFolders($parentFolder: String) {\n    getUserFolders(parentFolder: $parentFolder) {\n      id\n      name\n      parentFolder\n      isShared\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GetPlacementPowerConsumptionHistory(\n    $id: ID!\n    $startTime: DateTime\n    $endTime: DateTime\n    $timespan: Timespan\n    $multiplier: Int!\n  ) {\n    getPlacementPowerConsumptionHistory(\n      id: $id\n      startTime: $startTime\n      endTime: $endTime\n      timespan: $timespan\n      multiplier: $multiplier\n    ) {\n      time\n      value\n    }\n  }\n'
): typeof documents['\n  query GetPlacementPowerConsumptionHistory(\n    $id: ID!\n    $startTime: DateTime\n    $endTime: DateTime\n    $timespan: Timespan\n    $multiplier: Int!\n  ) {\n    getPlacementPowerConsumptionHistory(\n      id: $id\n      startTime: $startTime\n      endTime: $endTime\n      timespan: $timespan\n      multiplier: $multiplier\n    ) {\n      time\n      value\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GetProductionLinePowerConsumptionHistory(\n    $id: ID!\n    $startTime: DateTime\n    $endTime: DateTime\n    $timespan: Timespan\n    $multiplier: Int!\n  ) {\n    getProductionLinePowerConsumptionHistory(\n      id: $id\n      startTime: $startTime\n      endTime: $endTime\n      timespan: $timespan\n      multiplier: $multiplier\n    ) {\n      time\n      value\n    }\n  }\n'
): typeof documents['\n  query GetProductionLinePowerConsumptionHistory(\n    $id: ID!\n    $startTime: DateTime\n    $endTime: DateTime\n    $timespan: Timespan\n    $multiplier: Int!\n  ) {\n    getProductionLinePowerConsumptionHistory(\n      id: $id\n      startTime: $startTime\n      endTime: $endTime\n      timespan: $timespan\n      multiplier: $multiplier\n    ) {\n      time\n      value\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GetSectionPowerConsumptionHistory(\n    $id: ID!\n    $startTime: DateTime\n    $endTime: DateTime\n    $timespan: Timespan\n    $multiplier: Int!\n  ) {\n    getSectionPowerConsumptionHistory(\n      id: $id\n      startTime: $startTime\n      endTime: $endTime\n      timespan: $timespan\n      multiplier: $multiplier\n    ) {\n      time\n      value\n    }\n  }\n'
): typeof documents['\n  query GetSectionPowerConsumptionHistory(\n    $id: ID!\n    $startTime: DateTime\n    $endTime: DateTime\n    $timespan: Timespan\n    $multiplier: Int!\n  ) {\n    getSectionPowerConsumptionHistory(\n      id: $id\n      startTime: $startTime\n      endTime: $endTime\n      timespan: $timespan\n      multiplier: $multiplier\n    ) {\n      time\n      value\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GetProductionLineConsumptionByCategory(\n    $id: ID!\n    $masterPlacement: String!\n    $placements: [ID!]!\n    $operationalStates: [OperationalStates!]!\n    $operationalIntervalMins: [Float!]!\n    $operationalIntervalMaxs: [Float!]!\n    $startTime: DateTime\n    $endTime: DateTime\n  ) {\n    getProductionLineConsumptionByCategory(\n      id: $id\n      masterPlacement: $masterPlacement\n      placements: $placements\n      operationalStates: $operationalStates\n      operationalIntervalMins: $operationalIntervalMins\n      operationalIntervalMaxs: $operationalIntervalMaxs\n      startTime: $startTime\n      endTime: $endTime\n    ) {\n      consumptions {\n        consumption\n        state\n        time\n      }\n    }\n  }\n'
): typeof documents['\n  query GetProductionLineConsumptionByCategory(\n    $id: ID!\n    $masterPlacement: String!\n    $placements: [ID!]!\n    $operationalStates: [OperationalStates!]!\n    $operationalIntervalMins: [Float!]!\n    $operationalIntervalMaxs: [Float!]!\n    $startTime: DateTime\n    $endTime: DateTime\n  ) {\n    getProductionLineConsumptionByCategory(\n      id: $id\n      masterPlacement: $masterPlacement\n      placements: $placements\n      operationalStates: $operationalStates\n      operationalIntervalMins: $operationalIntervalMins\n      operationalIntervalMaxs: $operationalIntervalMaxs\n      startTime: $startTime\n      endTime: $endTime\n    ) {\n      consumptions {\n        consumption\n        state\n        time\n      }\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GetPlacementStandbyConsumption(\n    $id: ID!\n    $startTime: DateTime\n    $endTime: DateTime\n    $timespan: Timespan\n    $multiplier: Int!\n  ) {\n    getPlacementStandbyConsumption(\n      id: $id\n      startTime: $startTime\n      endTime: $endTime\n      timespan: $timespan\n      multiplier: $multiplier\n    ) {\n      costs\n      currency\n      emissions\n      energy\n      power {\n        time\n        value\n      }\n    }\n  }\n'
): typeof documents['\n  query GetPlacementStandbyConsumption(\n    $id: ID!\n    $startTime: DateTime\n    $endTime: DateTime\n    $timespan: Timespan\n    $multiplier: Int!\n  ) {\n    getPlacementStandbyConsumption(\n      id: $id\n      startTime: $startTime\n      endTime: $endTime\n      timespan: $timespan\n      multiplier: $multiplier\n    ) {\n      costs\n      currency\n      emissions\n      energy\n      power {\n        time\n        value\n      }\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GetProductionLineStandbyConsumption(\n    $id: ID!\n    $startTime: DateTime\n    $endTime: DateTime\n    $timespan: Timespan\n    $multiplier: Int!\n  ) {\n    getProductionLineStandbyConsumption(\n      id: $id\n      startTime: $startTime\n      endTime: $endTime\n      timespan: $timespan\n      multiplier: $multiplier\n    ) {\n      costs\n      currency\n      emissions\n      energy\n      power {\n        time\n        value\n      }\n    }\n  }\n'
): typeof documents['\n  query GetProductionLineStandbyConsumption(\n    $id: ID!\n    $startTime: DateTime\n    $endTime: DateTime\n    $timespan: Timespan\n    $multiplier: Int!\n  ) {\n    getProductionLineStandbyConsumption(\n      id: $id\n      startTime: $startTime\n      endTime: $endTime\n      timespan: $timespan\n      multiplier: $multiplier\n    ) {\n      costs\n      currency\n      emissions\n      energy\n      power {\n        time\n        value\n      }\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GetSectionStandbyConsumption(\n    $id: ID!\n    $startTime: DateTime\n    $endTime: DateTime\n    $timespan: Timespan\n    $multiplier: Int!\n  ) {\n    getSectionStandbyConsumption(\n      id: $id\n      startTime: $startTime\n      endTime: $endTime\n      timespan: $timespan\n      multiplier: $multiplier\n    ) {\n      costs\n      currency\n      emissions\n      energy\n      power {\n        time\n        value\n      }\n    }\n  }\n'
): typeof documents['\n  query GetSectionStandbyConsumption(\n    $id: ID!\n    $startTime: DateTime\n    $endTime: DateTime\n    $timespan: Timespan\n    $multiplier: Int!\n  ) {\n    getSectionStandbyConsumption(\n      id: $id\n      startTime: $startTime\n      endTime: $endTime\n      timespan: $timespan\n      multiplier: $multiplier\n    ) {\n      costs\n      currency\n      emissions\n      energy\n      power {\n        time\n        value\n      }\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GetPlacementTotalConsumption(\n    $id: ID!\n    $startTime: DateTime\n    $endTime: DateTime\n    $timespan: Timespan\n    $multiplier: Int!\n  ) {\n    getPlacementTotalConsumption(\n      id: $id\n      startTime: $startTime\n      endTime: $endTime\n      timespan: $timespan\n      multiplier: $multiplier\n    ) {\n      costs\n      currency\n      emissions\n      energy\n      power {\n        time\n        value\n      }\n    }\n  }\n'
): typeof documents['\n  query GetPlacementTotalConsumption(\n    $id: ID!\n    $startTime: DateTime\n    $endTime: DateTime\n    $timespan: Timespan\n    $multiplier: Int!\n  ) {\n    getPlacementTotalConsumption(\n      id: $id\n      startTime: $startTime\n      endTime: $endTime\n      timespan: $timespan\n      multiplier: $multiplier\n    ) {\n      costs\n      currency\n      emissions\n      energy\n      power {\n        time\n        value\n      }\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GetProductionLineTotalConsumption(\n    $id: ID!\n    $startTime: DateTime\n    $endTime: DateTime\n    $timespan: Timespan\n    $multiplier: Int!\n  ) {\n    getProductionLineTotalConsumption(\n      id: $id\n      startTime: $startTime\n      endTime: $endTime\n      timespan: $timespan\n      multiplier: $multiplier\n    ) {\n      costs\n      currency\n      emissions\n      energy\n      power {\n        time\n        value\n      }\n    }\n  }\n'
): typeof documents['\n  query GetProductionLineTotalConsumption(\n    $id: ID!\n    $startTime: DateTime\n    $endTime: DateTime\n    $timespan: Timespan\n    $multiplier: Int!\n  ) {\n    getProductionLineTotalConsumption(\n      id: $id\n      startTime: $startTime\n      endTime: $endTime\n      timespan: $timespan\n      multiplier: $multiplier\n    ) {\n      costs\n      currency\n      emissions\n      energy\n      power {\n        time\n        value\n      }\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GetSectionTotalConsumption(\n    $id: ID!\n    $startTime: DateTime\n    $endTime: DateTime\n    $timespan: Timespan\n    $multiplier: Int!\n  ) {\n    getSectionTotalConsumption(\n      id: $id\n      startTime: $startTime\n      endTime: $endTime\n      timespan: $timespan\n      multiplier: $multiplier\n    ) {\n      costs\n      currency\n      emissions\n      energy\n      power {\n        time\n        value\n      }\n    }\n  }\n'
): typeof documents['\n  query GetSectionTotalConsumption(\n    $id: ID!\n    $startTime: DateTime\n    $endTime: DateTime\n    $timespan: Timespan\n    $multiplier: Int!\n  ) {\n    getSectionTotalConsumption(\n      id: $id\n      startTime: $startTime\n      endTime: $endTime\n      timespan: $timespan\n      multiplier: $multiplier\n    ) {\n      costs\n      currency\n      emissions\n      energy\n      power {\n        time\n        value\n      }\n    }\n  }\n']

export function graphql(source: string) {
  return (documents as any)[source] ?? {}
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> =
  TDocumentNode extends DocumentNode<infer TType, any> ? TType : never

import React from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {SelectedRowsProvider} from '../../../providers/SelectedRowsProvider'
import {PartsWrapper} from './PartsWrapper'

const breadcrumbs: Array<PageLink> = [
  {
    title: 'Parts',
    path: '/parts/overview',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const PartsPage = () => (
  <Routes>
    <Route
      path='overview/grid?'
      element={
        <SelectedRowsProvider>
          <PageTitle breadcrumbs={breadcrumbs}>Overview</PageTitle>
          <PartsWrapper />
        </SelectedRowsProvider>
      }
    />
    <Route index element={<Navigate to='/parts/overview' />} />
  </Routes>
)

export default PartsPage

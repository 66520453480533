import {Outlet, Route, Routes} from 'react-router-dom'
import {PageTitle} from '../../../_metronic/layout/core'

const TasksPage = () => {
  return (
    <Routes>
      <Route path='' element={<PageTitle>Tasks</PageTitle>} />
    </Routes>
  )
}

export default TasksPage

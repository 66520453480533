import {useEffect, useRef} from 'react'
import * as am5 from '@amcharts/amcharts5'
import {useAssetGraphs} from '../../../../core/requests/factory'
import {anomalyChartConfig} from '../../core/_chartConfigs/anomalyChartConfig'
import {ChartLoader} from '../ChartLoader'

type AnomalyGraphProps = {
  assetId: string
  startTime?: string
  endTime?: string
  height: string
  chartId?: string
}

const AnomalyGraph = ({
  assetId,
  startTime,
  endTime,
  height,
  chartId = `anomaly-${assetId}`,
}: AnomalyGraphProps) => {
  const chartRef = useRef<am5.Root | null>(null)

  const {data: assetGraphs} = useAssetGraphs({assetId, startTime, endTime})

  const loading = assetGraphs === undefined

  useEffect(() => {
    if (loading) return

    chartRef.current = anomalyChartConfig(chartId, assetGraphs.anomaly_history)

    return () => {
      chartRef.current?.dispose()
    }
  }, [assetGraphs, loading])

  return <ChartLoader loading={loading} chartId={chartId} height={height} />
}

export {AnomalyGraph}

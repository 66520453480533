import {useInfiniteQuery} from 'react-query'
import {Navigate, Route, Routes, useParams} from 'react-router-dom'
import {fetchBatches} from '../../core/requests/oee'
import {SelectedRowsProvider, useSelectedRows} from '../../providers/SelectedRowsProvider'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {fetchWorkOrders} from '../../core/requests/maintenance'
import {useProductionLine} from '../../core/requests/factory'
import Columns from './Columns'
import React, {useMemo} from 'react'
import {KTCard} from '../../../_metronic/helpers'
import {TableBody} from '../overview/core/columns/TableBody'
import {BaseModel, Batch, PaginatedResponse, WorkOrder} from '../../core/_models'

type Props = {
  elements: (Batch | WorkOrder)[]
  loading: boolean
}

const Schedule = ({elements, loading}: Props) => {
  const {selected} = useSelectedRows()
  const columns = useMemo(() => Columns, [])

  return (
    <KTCard>
      {/* Header */}
      {/* <div className='card-header border-0 pt-6'>
        <ListSearchComponent />
        <div className='card-toolbar'>
          {selected.length === 0 && (
            <TableToolbar
              buttonText='Add Production Line'
              onButtonClick={openCreateProductionLineModal}
            />
          )}

          {selected.length > 0 && (
            <TableSelectionToolbar onDelete={deleteProductionLine} queryKey='productionLines' />
          )}
        </div>
      </div> */}

      {/* Body */}
      <TableBody
        tableId='hs_schedule_table'
        columns={columns}
        dataList={elements}
        loading={loading}
      />
    </KTCard>
  )
}

const ScheduleWrapper = () => {
  let {id} = useParams()
  const {data: productionLine} = useProductionLine(id)

  const {
    data: batchesData,
    isFetchingNextPage: batchesFetchingNextPage,
    fetchNextPage: batchesFetchNextPage,
    hasNextPage: batchesHaveNextPage,
  } = useInfiniteQuery<PaginatedResponse<Batch & BaseModel>>({
    queryKey: ['batches'],
    queryFn: async (q) =>
      (
        await fetchBatches({
          page: q.pageParam,
          production_line: id,
        })
      ).data,
    getNextPageParam: (lastPage, pages) =>
      lastPage.page < lastPage.pages ? lastPage.page + 1 : undefined,
    getPreviousPageParam: (lastPage, pages) => (lastPage.page > 1 ? lastPage.page - 1 : undefined),
  })

  React.useEffect(() => {
    if (batchesHaveNextPage && !batchesFetchingNextPage) {
      batchesFetchNextPage()
    }
  }, [batchesHaveNextPage, batchesFetchingNextPage])

  const batches = batchesData?.pages
    .map((page) => page.items)
    .reduce((previousValue, currentValue, currentIndex, array) => [
      ...previousValue,
      ...currentValue,
    ])

  const {
    data: workOrdersData,
    isFetchingNextPage: workOrdersFetchingNextPage,
    fetchNextPage: workOrdersFetchNextPage,
    hasNextPage: workOrdersHaveNextPage,
  } = useInfiniteQuery<PaginatedResponse<WorkOrder & BaseModel>>({
    queryKey: ['workOrders'],
    queryFn: async (q) =>
      await fetchWorkOrders({
        page: q.pageParam,
        factory_entities: id !== undefined ? [id] : undefined,
      }),
    getNextPageParam: (lastPage, pages) =>
      lastPage.page < lastPage.pages ? lastPage.page + 1 : undefined,
    getPreviousPageParam: (lastPage, pages) => (lastPage.page > 1 ? lastPage.page - 1 : undefined),
    enabled: id !== undefined,
  })

  React.useEffect(() => {
    if (workOrdersHaveNextPage && !workOrdersFetchingNextPage) {
      workOrdersFetchNextPage()
    }
  }, [workOrdersHaveNextPage, workOrdersFetchingNextPage])

  const workOrders = workOrdersData?.pages
    .map((page) => page.items || [])
    .reduce((previousValue, currentValue, currentIndex, array) => [
      ...previousValue,
      ...currentValue,
    ])

  const sortedElements = [...(batches || []), ...(workOrders || [])].sort(
    (a, b) => new Date(b.planned_start_time).getTime() - new Date(a.planned_start_time).getTime()
  )

  function breadcrumbs(): Array<PageLink> {
    if (!productionLine) return []

    return [
      {
        title: productionLine.name,
        path: '',
        isSeparator: false,
        isActive: false,
      },
      {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
      },
    ]
  }

  return (
    <SelectedRowsProvider data={sortedElements} idField='_id'>
      <PageTitle breadcrumbs={breadcrumbs()}>Schedule</PageTitle>
      <Schedule
        elements={sortedElements}
        loading={batchesFetchingNextPage || workOrdersFetchingNextPage}
      />
    </SelectedRowsProvider>
  )
}

const SchedulePage = () => {
  return (
    <Routes>
      <Route path=':id' element={<ScheduleWrapper />} />
      <Route index element={<Navigate to='/' />} />
    </Routes>
  )
}

export default SchedulePage

import clsx from 'clsx'
import {KTSVG} from '../../../helpers'
import {useDisplayMode} from './DisplayModeProvider'

// type Props = {
//   toggleBtnClass?: string
//   toggleBtnIconClass?: string
//   menuPlacement?: string
//   menuTrigger?: string
// }

const DisplayModeSwitcher = () => {
  const {displayMode, updateDisplayMode} = useDisplayMode()

  return (
    <>
      <a
        href='#'
        className='btn btn-active-light-primary px-5'
        data-kt-menu-trigger="{default: 'click'}"
        data-kt-menu-attach='parent'
        data-kt-menu-placement='bottom-end'
      >
        {/* <KTSVG path='/media/icons/duotune/coding/cod001.svg' className='svg-icon-2' /> */}

        {/* <span className='svg-icon svg-icon-2'>
      <SVG src={toAbsoluteUrl(path)} className={svgClassName} />
    </span> */}
        {displayMode === 'oee' && (
          <>
            <i className='fa-solid fa-chart-line me-1'></i>OEE
          </>
        )}
        {displayMode === 'energy' && (
          <>
            <i className='fa-solid fa-plug me-1'></i>Energy
          </>
        )}
        {displayMode === 'condition' && (
          <>
            <i className='fa-solid fa-heart-pulse me-1'></i>Condition
          </>
        )}
      </a>

      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-muted menu-active-bg menu-state-primary fw-semibold py-4 fs-base w-150px'
        data-kt-menu='true'
      >
        <div className='menu-item px-3 my-0'>
          <a
            href='#'
            className={clsx('menu-link px-3 py-2', {active: displayMode === 'oee'})}
            onClick={() => updateDisplayMode('oee')}
          >
            <span className='menu-icon' data-kt-element='icon'>
              {/* <KTSVG path='/media/icons/duotune/coding/cod001.svg' className='svg-icon-3' /> */}
              <i className='fa-solid fa-chart-line' />
            </span>
            <span className='menu-title'>OEE</span>
          </a>
        </div>

        <div className='menu-item px-3 my-0'>
          <a
            href='#'
            className={clsx('menu-link px-3 py-2', {active: displayMode === 'energy'})}
            onClick={() => updateDisplayMode('energy')}
          >
            <span className='menu-icon' data-kt-element='icon'>
              <i className='fa-solid fa-plug' />
            </span>
            <span className='menu-title'>Energy</span>
          </a>
        </div>

        <div className='menu-item px-3 my-0'>
          <a
            href='#'
            className={clsx('menu-link px-3 py-2', {active: displayMode === 'condition'})}
            onClick={() => updateDisplayMode('condition')}
          >
            <span className='menu-icon' data-kt-element='icon'>
              <i className='fa-solid fa-heart-pulse' />
            </span>
            <span className='menu-title'>Condition</span>
          </a>
        </div>
      </div>
    </>
  )
}

export {DisplayModeSwitcher}

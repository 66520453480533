import React from 'react'
import {KTCard} from '../../../../_metronic/helpers'
import Pagination from '../../../components/pagination/Pagination'
import {deleteSection, useSections} from '../../../core/requests/factory'
import useGridSwitch from '../../../hooks/UseGridSwitch'
import {useCreateModals} from '../../../modals/CreateModalsProvider'
import {useSelectedRows} from '../../../providers/SelectedRowsProvider'
import {TableBody} from '../core/columns/TableBody'
import {TableSelectionToolbar} from '../core/columns/TableSelectionToolbar'
import {TableToolbar} from '../core/columns/TableToolbar'
import ListFilter from '../core/components/ListFilter'
import OrganizationSelect from '../core/components/OrganizationSelect'
import {OverviewGridItem} from '../core/components/overview/OverviewGridItem'
import ProductionLineSelect from '../core/components/ProductionLineSelect'
import useSectionsFilter from '../core/hooks/useSectionsFilter'
import {ListSearchComponent} from '../core/TableSearchComponent'
import {Columns} from './Columns'

export const SectionsWrapper = () => {
  const {isGridView} = useGridSwitch({switchUrl: '/sections/overview'})

  const {selected, setDataItems} = useSelectedRows()
  const {openCreateSectionModal} = useCreateModals()
  const {filterState, filterCount, activePage, setActivePage, sectionFilterProps} =
    useSectionsFilter()
  const {data: sections, isLoading: sectionsLoading} = useSections(sectionFilterProps)
  const columns = React.useMemo(() => Columns, [])

  React.useEffect(() => {
    if (sections?.items !== undefined) {
      setDataItems(sections?.items)
    }
  }, [sections?.items])

  return (
    <KTCard className={`${isGridView ? 'bg-transparent' : undefined}`}>
      {/* Header */}
      <div
        className={`card-header border-0 justify-content-end pt-6 bg-white ${
          isGridView ? 'mb-5 pb-5 rounded' : ''
        }`}
      >
        {/* <ListSearchComponent /> */}
        <div className='card-toolbar'>
          <ListFilter
            count={filterCount}
            filters={[
              <OrganizationSelect onChange={filterState.organization.setValue} />,
              <ProductionLineSelect onChange={filterState.productionLine.setValue} />,
            ]}
          />

          {/* Standard toolbar */}
          {selected.length === 0 && (
            <TableToolbar buttonText='Add' onButtonClick={openCreateSectionModal} />
          )}

          {/* Selection toolbar */}
          {selected.length > 0 && (
            <TableSelectionToolbar onDelete={deleteSection} queryKey='sections' />
          )}
        </div>
      </div>

      {isGridView ? (
        <div className={`row g-6 g-xl-9 mb-4`} id='hs_sections_table'>
          {sections?.items.map((item) => (
            <OverviewGridItem
              item={item}
              title={item.name}
              description={item.production_line.name}
              imageUrl={item.gallery?.profile_image.thumbnail_url}
            />
          ))}
        </div>
      ) : (
        <TableBody
          tableId='hs_sections_table'
          columns={columns}
          dataList={sections?.items}
          loading={sectionsLoading}
        />
      )}
      {sections?.total ? (
        <Pagination
          page={activePage}
          total={sections.total}
          size={sections.size}
          onClick={(p) => setActivePage(p)}
        />
      ) : null}
    </KTCard>
  )
}

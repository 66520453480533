import React from 'react'
import {Link, Navigate, Route, Routes, useParams} from 'react-router-dom'
import {KTSVG} from '../../../_metronic/helpers'
import {PageTitle, useLayout} from '../../../_metronic/layout/core'
import {SelectedRowsProvider} from '../../providers/SelectedRowsProvider'
import {SuspensedView} from '../../routing/PrivateRoutes'

const WorkOrdersWrapper = React.lazy(() => import('./WorkOrdersWrapper'))

const WorkOrdersPage = () => {
  const {setToolbar} = useLayout()
  const {'*': splat} = useParams()
  const currentView = splat?.split('/')[0]

  const toolbar = React.useMemo(
    () => (
      <>
        <PageTitle>Work Orders</PageTitle>
        <div>
          <Link
            className={`me-2 py-2 px-3 btn btn-sm btn-light ${
              currentView === '' ? 'btn-primary' : 'btn-secondary'
            }`}
            to={`/work-orders`}
          >
            <KTSVG path='/media/icons/duotune/general/gen014.svg' className='svg-icon-3 me-1' />
            Calendar
          </Link>
          <Link
            className={`me-2 py-2 px-3 btn btn-sm btn-light ${
              currentView === 'list' ? 'btn-primary' : 'btn-secondary'
            }`}
            to={`/work-orders/list`}
          >
            <KTSVG path='/media/icons/duotune/general/gen005.svg' className='svg-icon-3 me-1' />
            List
          </Link>
          <Link
            className={`py-2 px-3 btn btn-sm btn-light ${
              currentView === 'panel' ? 'btn-primary' : 'btn-secondary'
            }`}
            to={`/work-orders/panel`}
          >
            <KTSVG path='/media/icons/duotune/layouts/lay008.svg' className='svg-icon-3 me-1' />
            Panel
          </Link>
        </div>
      </>
    ),
    [currentView]
  )

  React.useEffect(() => {
    setToolbar(toolbar)
    return () => {
      setToolbar(undefined)
    }
  }, [setToolbar, toolbar])

  return (
    <Routes>
      <Route
        index={true}
        path=''
        element={
          <SuspensedView>
            <WorkOrdersWrapper view={`Calendar`} />
          </SuspensedView>
        }
      />
      <Route
        path='list'
        element={
          <SuspensedView>
            <SelectedRowsProvider>
              <WorkOrdersWrapper view={`List`} />
            </SelectedRowsProvider>
          </SuspensedView>
        }
      />
      <Route
        path='panel/:workOrderId?'
        element={
          <SuspensedView>
            <WorkOrdersWrapper view={`Panel`} />
          </SuspensedView>
        }
      />
      {/* Page Not Found */}
      <Route path='*' element={<Navigate to='/error/404' />} />
    </Routes>
  )
}

export default WorkOrdersPage

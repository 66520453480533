import {GraphQLClient} from 'graphql-request'
import {useAuth} from '../../modules/auth'
import * as config from '../config'

export enum GraphQLURL {
  ENERGY = 'WA_ENERGY_GRAPHQL_URL',
  DASHBOARDS = 'WA_DASHBOARDS_GRAPHQL_URL',
}

interface Props {
  url: GraphQLURL
}

const useBaseGraphQLClient = ({url}: Props) => {
  const {authToken} = useAuth()
  return new GraphQLClient(config[url], {
    errorPolicy: 'all',
    headers: {
      Authorization: `Bearer ${authToken.access_token}`,
    },
  })
}

export default useBaseGraphQLClient

import React from 'react'
import {useParams} from 'react-router-dom'
import {useLayout} from '../../_metronic/layout/core'
import OverviewModeSwitch from '../pages/overview/core/components/overview/OverviewModeSwitch'

interface UseGridSwitchProps {
  switchUrl: string
}

const useGridSwitch = ({switchUrl}: UseGridSwitchProps) => {
  const {'*': splat} = useParams()
  const isGridView = splat?.split('/')[1] === 'grid'

  const {setToolbar} = useLayout()

  React.useEffect(() => {
    setToolbar(<OverviewModeSwitch switchUrl={switchUrl} />)
    return () => {
      setToolbar(undefined)
    }
  }, [setToolbar, switchUrl])

  return {
    isGridView,
  }
}

export default useGridSwitch

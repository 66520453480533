import {useQueryClient} from 'react-query'
import {WorkOrderTemplate} from '../../../core/_models'
import {useEffect, useState} from 'react'
import StepModalWrapper, {Step} from '../../../components/StepModalWrapper'
import {
  ICreateWorkOrderTemplate,
  RecurrencePattern,
  defaultCreateWorkOrderTemplate,
} from './_models'
import Step1 from './Step1'
import {createWorkOrderTemplate, updateWorkOrderTemplate} from '../../../core/requests/maintenance'
import Step3 from './Step3'
import Step2 from './Step2'
import {useFactoryEntities} from '../../../core/requests/factory'

type Props = {
  show: boolean
  hide: () => void
  maintenancePlanId: string
  edit?: WorkOrderTemplate
}

const AddWorkOrderTemplateModal = ({show, hide, maintenancePlanId, edit}: Props) => {
  const queryClient = useQueryClient()
  const [data, setData] = useState<ICreateWorkOrderTemplate>(defaultCreateWorkOrderTemplate)

  const {data: factoryEntities} = useFactoryEntities({
    entityIds: edit && edit.factory_entity ? [edit.factory_entity] : [],
    options: {enabled: !!edit && !!edit.factory_entity},
  })

  useEffect(() => {
    if (!show) return
    if (edit) {
      const isRecurring = edit.recurring_periods && edit.recurring_periods.length > 0
      const newCreateWorkOrderTemplate = {
        ...edit,
        // nex
        recurrence_pattern: (isRecurring
          ? 'calendar_days'
          : 'operational_hours') as RecurrencePattern,
        recurring_dates: isRecurring ? edit.recurring_periods![0].recurring_dates : undefined,
        recurring_months: isRecurring ? edit.recurring_periods![0].recurring_months : undefined,
        recurring_weekdays: isRecurring ? edit.recurring_periods![0].recurring_days : undefined,
        recurring_start_time: isRecurring ? edit.recurring_periods![0].start_time : undefined,
        selected_entity_type:
          !factoryEntities || !factoryEntities.items[0] ? undefined : factoryEntities.items[0].type,
      }
      setData(newCreateWorkOrderTemplate)
    } else {
      setData({...defaultCreateWorkOrderTemplate})
    }
  }, [show, edit, factoryEntities])

  const steps: Step<ICreateWorkOrderTemplate>[] = [
    {
      title: 'Specifics',
      description: 'Define Work Order Specifics',
      component: Step1,
      validation: (data) => data.title === undefined || data.expected_duration === undefined,
    },
    {
      title: 'Recurrence',
      description: 'Define Work Order Recurrence specifics',
      component: Step2,
      validation: (data) =>
        data.recurrence_pattern === undefined ||
        (data.recurrence_pattern === 'operational_hours' &&
          data.recurring_hourly_interval === undefined) ||
        (data.recurrence_pattern === 'calendar_days' && data.recurring_start_time === undefined) ||
        (data.recurrence_pattern === 'calendar_days' &&
          data.recurring_weekdays === undefined &&
          (data.recurring_months === undefined || data.recurring_dates === undefined)) ||
        (data.recurrence_pattern === 'calendar_days' &&
          data.recurring_dates === undefined &&
          data.recurring_months === undefined &&
          data.recurring_weekdays === undefined) ||
        data.max_future_jobs === undefined,
    },
    {
      title: 'Category',
      description: 'Define Work Order Category',
      component: Step3,
    },
  ]

  const submit = async (data: ICreateWorkOrderTemplate) => {
    data.maintenance_plan = maintenancePlanId

    if (data.recurrence_pattern === 'calendar_days') {
      data.recurring_periods = [
        {
          start_time: data.recurring_start_time!,
          recurring_days: data.recurring_weekdays!,
          recurring_dates: data.recurring_dates!,
          recurring_months: data.recurring_months!,
        },
      ]
    }

    const newWorkOrderTemplate = edit
      ? await updateWorkOrderTemplate(edit._id, data)
      : await createWorkOrderTemplate(data)
    await queryClient.invalidateQueries({
      queryKey: ['workOrderTemplates'],
    })
    return newWorkOrderTemplate
  }

  return (
    <StepModalWrapper<ICreateWorkOrderTemplate, WorkOrderTemplate>
      id='hs_create_work_order_template_modal'
      objectName='Recurring Work Order'
      show={show}
      close={hide}
      steps={steps}
      data={data}
      defaultData={defaultCreateWorkOrderTemplate}
      setData={setData}
      submitData={submit}
      loading={!!edit && !!edit.factory_entity && factoryEntities === undefined}
    />
  )
}

export {AddWorkOrderTemplateModal}

import {useLocation} from 'react-router-dom'
import {checkIsActive} from '../../../helpers'
import {ProductionLineToolbar} from '../../../partials/layout/lookback/LookbackSwitcher'
import {PlannerToolbar} from './PlannerToolbar'
import {OrganizationToolbar} from './OrganizationToolbar'
import {DashboardToolbar} from './DashboardToolbar'
import {useLayout} from '../../core'

const Toolbar = () => {
  const {pathname} = useLocation()
  const {toolbar} = useLayout()

  if (checkIsActive(pathname, '/production-lines', ['/production-lines/overview']))
    return <ProductionLineToolbar />
  else if (checkIsActive(pathname, '/dashboards_v2')) return null
  else if (checkIsActive(pathname, '/dashboard')) return <DashboardToolbar />
  else if (checkIsActive(pathname, '/planner')) return <PlannerToolbar />
  else if (checkIsActive(pathname, '/organization')) return <OrganizationToolbar />
  else return <>{toolbar}</>
}

export {Toolbar}

import {AssetState} from '../../core/_models'

type AssetStateProps = {
  state: AssetState
  className?: string
}

const AssetStateBadge = ({state, className}: AssetStateProps) => {
  return <div className={`badge bg-state-${state.toLowerCase()} ${className}`}>{state}</div>
}

export default AssetStateBadge

import {useEffect, useRef, useState} from 'react'
import Select, {MultiValue} from 'react-select'

type Props = {
  initial?: (100 | 200 | 300 | 400)[]
  name?: string
  onchange: (value: (100 | 200 | 300 | 400)[]) => void
  errorOccurred?: boolean
  className?: string
}

const ZonesSelectField = ({
  initial = [],
  name = 'Zones',
  onchange,
  errorOccurred,
  className,
}: Props) => {
  const [selectedOptions, setSelectedOptions] = useState<MultiValue<OptionType>>([])
  const initialOptionsSet = useRef(false)

  const handleChange = (selected: MultiValue<OptionType>) => {
    onchange(selected.map((option) => option.value))
    setSelectedOptions(selected)
  }

  useEffect(() => {
    if (!initial.length || initialOptionsSet.current) return
    setSelectedOptions(options.filter((option) => initial.includes(option.value)))
    initialOptionsSet.current = true
  }, [initial])

  const error = errorOccurred && !selectedOptions.length

  return (
    <>
      <div className={`fs-7 ${className}`}>{name}</div>
      <Select
        value={selectedOptions}
        options={options}
        onChange={handleChange}
        styles={{
          control: (baseStyles) => ({
            ...baseStyles,
            borderColor: error ? 'red' : baseStyles.borderColor,
          }),
        }}
        isMulti
        isClearable
      />
      {error && <div className='text-danger fs-8'>This field is required</div>}
    </>
  )
}

type OptionType = {
  value: 100 | 200 | 300 | 400
  label: string
}

const options: OptionType[] = [
  {value: 100, label: '100'},
  {value: 200, label: '200'},
  {value: 300, label: '300'},
  {value: 400, label: '400'},
]

export default ZonesSelectField

import {useEventCategories, useEventLogs} from '../core/requests/oee'

type Props = {
  machineId: string
}

const OperationalStateBox = ({machineId}: Props) => {
  const {data: eventLogs} = useEventLogs({
    entities: [machineId],
    order: '-time',
    options: {
      refetchInterval: 60 * 1000,
    },
  })
  const latestEventLog = eventLogs?.items.at(0)

  const {data: eventCategories} = useEventCategories({
    ids: latestEventLog?.categories,
    options: {
      enabled: latestEventLog !== undefined,
      refetchInterval: 60 * 1000,
    },
  })
  const categoryOfLatestEvent = eventCategories?.items.at(0)

  const currentlyRunning = categoryOfLatestEvent?.operational || false
  const latestEventState = categoryOfLatestEvent?.title
  const latestEventTime = latestEventLog?.unix
  const minutesInLatestState = latestEventTime && (Date.now() - latestEventTime * 1000) / 60000

  return (
    <div className={`bg-${currentlyRunning ? 'success' : 'danger'} rounded-3 p-2 flex-grow-1 mb-2`}>
      <span className='text-white fs-2 fw-bold d-flex align-items-center justify-content-center h-100'>
        {latestEventState?.toUpperCase() || 'N/A'} - {minutesInLatestState?.toFixed(0) || '?'}{' '}
        minutes
      </span>
    </div>
  )
}

export default OperationalStateBox

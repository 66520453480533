import React, {Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {dashboardV2Enabled} from '../core/config'
import {DashboardsPage} from '../pages/dashboard/DashboardWrapper'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import DashboardsRouter from '../pages/dashboard_v2/DashboardsRouter'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import AreasPage from '../pages/overview/areas/AreasPage'
import ProductionLinesPage from '../pages/overview/production-lines/ProductionLinesPage'
import SectionsPage from '../pages/overview/sections/SectionPage'
import AssetsPage from '../pages/overview/assets/AssetPage'
import PlacementsPage from '../pages/overview/placements/PlacementPage'
import SensorsPage from '../pages/overview/sensors/SensorPage'
import {OrganizationPage} from '../pages/organization/OrganizationPage'
import PartsPage from '../pages/overview/parts/PartsPage'
import FactoriesPage from '../pages/overview/factories/FactoriesPage'
import GatewaysPage from '../pages/overview/gateways/GatewaysPage'
import {SupportWrapper} from '../pages/support/SupportWrapper'
import DevicesPage from '../pages/staff/DevicesPage'
import StatesPage from '../pages/staff/StatesPage'
import TasksPage from '../pages/staff/TasksPage'
import PlannerPage from '../pages/planner/PlannerPage'
import SchedulePage from '../pages/schedule/SchedulePage'
import WorkOrdersPage from '../pages/work-orders/WorkOrdersPage'
import PlansPage from '../pages/plans/PlansPage'

const PrivateRoutes = () => {
  const dashboardV2Router = dashboardV2Enabled ? (
    <Route path='dashboards_v2/*' element={<DashboardsRouter />} />
  ) : null
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path='auth/*' element={<Navigate to='/factories' />} />

        {/* Pages */}
        {dashboardV2Router}
        <Route path='dashboards/*' element={<DashboardsPage />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='support' element={<SupportWrapper />} />

        <Route path='organization' element={<OrganizationPage />} />
        <Route path='planner/*' element={<PlannerPage />} />

        <Route path='work-orders/*' element={<WorkOrdersPage />} />
        <Route path='plans' element={<PlansPage />} />

        <Route path='staff/devices' element={<DevicesPage />} />
        <Route path='staff/states' element={<StatesPage />} />
        <Route path='staff/tasks' element={<TasksPage />} />

        <Route
          path='factories/*'
          element={
            <SuspensedView>
              <FactoriesPage />
            </SuspensedView>
          }
        />

        <Route
          path='areas/*'
          element={
            <SuspensedView>
              <AreasPage />
            </SuspensedView>
          }
        />

        <Route
          path='production-lines/*'
          element={
            <SuspensedView>
              <ProductionLinesPage />
            </SuspensedView>
          }
        />

        <Route
          path='schedule/*'
          element={
            <SuspensedView>
              <SchedulePage />
            </SuspensedView>
          }
        />

        <Route
          path='sections/*'
          element={
            <SuspensedView>
              <SectionsPage />
            </SuspensedView>
          }
        />

        <Route
          path='assets/*'
          element={
            <SuspensedView>
              <AssetsPage />
            </SuspensedView>
          }
        />

        <Route
          path='parts/*'
          element={
            <SuspensedView>
              <PartsPage />
            </SuspensedView>
          }
        />

        <Route
          path='placements/*'
          element={
            <SuspensedView>
              <PlacementsPage />
            </SuspensedView>
          }
        />

        <Route
          path='sensors/*'
          element={
            <SuspensedView>
              <SensorsPage />
            </SuspensedView>
          }
        />

        <Route
          path='gateways/*'
          element={
            <SuspensedView>
              <GatewaysPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: React.FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes, SuspensedView}

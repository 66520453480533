import {Route, Routes} from 'react-router-dom'
import {PageTitle} from '../../../_metronic/layout/core'
import {PlannerWrapper} from './PlannerWrapper'

const PlannerPage = () => (
  <Routes>
    <Route
      path=''
      element={
        <>
          <PageTitle>Planner</PageTitle>
          <PlannerWrapper />
        </>
      }
    />
  </Routes>
)

export default PlannerPage

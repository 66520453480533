import React from 'react'
import {KTCard} from '../../../../_metronic/helpers'
import LoadingWrapper from '../../../components/LoadingWrapper'
import {
  UseAssetsProps,
  useAssetsStatuses,
  UseGatewaysProps,
  useGatewaysStatuses,
  UseSensorsProps,
  useSensorsStatuses,
} from '../../../core/requests/factory'
import {PieGraph} from '../../dashboard/components/charts/PieGraph'
import {PieData} from '../../dashboard/core/_chartConfigs/pieConfig'
import AreaSelect from '../../overview/core/components/AreaSelect'
import DeviceStateSelect from '../../overview/core/components/DeviceStateSelect'
import ManufacturerSelect from '../../overview/core/components/ManufacturerSelect'
import OrganizationSelect from '../../overview/core/components/OrganizationSelect'
import PartModelSelect from '../../overview/core/components/PartModelSelect'
import SectionSelect from '../../overview/core/components/SectionSelect'
import SensorDataTypeSelect from '../../overview/core/components/SensorDataTypeSelect'
import SensorTrackScrapSelect from '../../overview/core/components/SensorTrackScrapSelect'
import ListFilter from '../../overview/core/components/ListFilter'
import useAssetsFilter from '../../overview/core/hooks/useAssetsFilter'
import useGatewaysFilter from '../../overview/core/hooks/useGatewaysFilter'
import useSensorsFilter from '../../overview/core/hooks/useSensorsFilter'

const StatesPageContent = () => {
  const id = React.useId()
  const {
    filterState: assetsFilterState,
    filterCount: assetsFilterCount,
    assetFilterProps,
  } = useAssetsFilter()

  const {
    filterState: sensorsFilterState,
    filterCount: sensorsFilterCount,
    sensorFilterProps,
  } = useSensorsFilter()

  const {
    filterState: gatewaysFilterState,
    filterCount: gatewaysFilterCount,
    gatewayFilterProps,
  } = useGatewaysFilter()

  const {isLoading: isLoadingAssetsStatuses, data: assetsStatuses} = useAssetsStatuses(
    assetFilterProps as Omit<UseAssetsProps, 'page' | 'size' | 'options'>
  )
  const {isLoading: isLoadingSensorsStatuses, data: sensorsStatuses} = useSensorsStatuses(
    sensorFilterProps as Omit<UseSensorsProps, 'page' | 'size' | 'options'>
  )
  const {isLoading: isLoadingGatewaysStatuses, data: gatewaysStatuses} = useGatewaysStatuses(
    gatewayFilterProps as Omit<UseGatewaysProps, 'page' | 'size' | 'options'>
  )

  const assetsStatusesData: PieData[] =
    assetsStatuses?.map((status) => ({
      label: status.state,
      value: status.count,
    })) || []

  const sensorsStatusesData: PieData[] =
    sensorsStatuses?.map((status) => ({
      label: status.state,
      value: status.count,
    })) || []

  const gatewaysStatusesData: PieData[] =
    gatewaysStatuses?.map((status) => ({
      label: status.state,
      value: status.count,
    })) || []

  return (
    <div className='container'>
      <div className={'row gap-5'}>
        <KTCard stretch={'stretch-50'} className={`col`}>
          <div className='card-header w-100 d-flex justify-content-between align-items-center'>
            <h3 className='card-title'>Assets</h3>
            <ListFilter
              count={assetsFilterCount}
              filters={[
                <OrganizationSelect onChange={assetsFilterState.organization.setValue} />,
                <AreaSelect onChange={assetsFilterState.area.setValue} />,
                <SectionSelect onChange={assetsFilterState.section.setValue} />,
              ]}
            />
          </div>
          <div className='body'>
            <LoadingWrapper loading={isLoadingAssetsStatuses}>
              <PieGraph id={`${id}-assets`} height={'200px'} data={assetsStatusesData} />
            </LoadingWrapper>
          </div>
        </KTCard>
        <KTCard stretch={'stretch-50'} className={`col`}>
          <div className='card-header w-100 d-flex justify-content-between align-items-center'>
            <h3 className='card-title'>Sensors</h3>
            <ListFilter
              count={sensorsFilterCount}
              filters={[
                <DeviceStateSelect onChange={sensorsFilterState.state.setValue} />,
                <AreaSelect onChange={sensorsFilterState.area.setValue} />,
                <SensorDataTypeSelect onChange={sensorsFilterState.dataType.setValue} />,
                <ManufacturerSelect onChange={sensorsFilterState.manufacturer.setValue} />,
                <PartModelSelect onChange={sensorsFilterState.model.setValue} />,
                <SensorTrackScrapSelect onChange={sensorsFilterState.trackScrap.setValue} />,
                <OrganizationSelect onChange={sensorsFilterState.organization.setValue} />,
              ]}
            />
          </div>
          <div className='body'>
            <LoadingWrapper loading={isLoadingSensorsStatuses}>
              <PieGraph id={`${id}-sensors`} height={'200px'} data={sensorsStatusesData} />
            </LoadingWrapper>
          </div>
        </KTCard>
        <div className='w-100'></div>
        <KTCard stretch={'stretch-50'} className={`col`}>
          <div className='card-header w-100 d-flex justify-content-between align-items-center'>
            <h3 className='card-title'>Gateways</h3>
            <ListFilter
              count={gatewaysFilterCount}
              filters={[
                <OrganizationSelect onChange={gatewaysFilterState.organization.setValue} />,
                <DeviceStateSelect onChange={gatewaysFilterState.state.setValue} />,
                <AreaSelect onChange={gatewaysFilterState.area.setValue} />,
              ]}
            />
          </div>
          <div className='body'>
            <LoadingWrapper loading={isLoadingGatewaysStatuses}>
              <PieGraph id={`${id}-gateways`} height={'200px'} data={gatewaysStatusesData} />
            </LoadingWrapper>
          </div>
        </KTCard>
        <div className='col'></div>
      </div>
    </div>
  )
}

export default StatesPageContent

import ZonesSelectField from '../fields/ZonesSelectField'
import {HealthMatchCondition} from './conditions'

type Props = {
  condition: HealthMatchCondition
  setCondition: (condition: HealthMatchCondition) => void
  errorOccurred?: boolean
}

const HealthMatch = ({condition, setCondition, errorOccurred}: Props) => {
  return (
    <>
      <ZonesSelectField
        name='Zones*'
        initial={condition.zones}
        onchange={(values) => setCondition({...condition, zones: values})}
        className='pt-3'
        errorOccurred={errorOccurred}
      />
    </>
  )
}

export default HealthMatch

import React from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {SelectedRowsProvider} from '../../../providers/SelectedRowsProvider'
import {PlacementsWrapper} from './PlacementsWrapper'

const breadcrumbs: Array<PageLink> = [
  {
    title: 'Placements',
    path: '/placements/overview',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const PlacementsPage = () => (
  <Routes>
    <Route
      path='overview/grid?'
      element={
        <SelectedRowsProvider>
          <PageTitle breadcrumbs={breadcrumbs}>Overview</PageTitle>
          <PlacementsWrapper />
        </SelectedRowsProvider>
      }
    />
    <Route index element={<Navigate to='/placements/overview' />} />
  </Routes>
)

export default PlacementsPage
